/**** Syntax Highlight overrides****/
.hljs-variable {
    color: white;
}
.hljs-number {
    font-weight: 600;
    color: #d81818;
}

/**** Fonts ****/

/* roboto-300 - latin */
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('https://appleptr16.com/static/fonts/roboto-v30-latin-300.woff2')
        format('woff2');
}
/* roboto-regular - latin */
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('https://appleptr16.com/static/fonts/roboto-v30-latin-regular.woff2')
        format('woff2');
}
/* roboto-500 - latin */
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('https://appleptr16.com/static/fonts/roboto-v30-latin-500.woff2')
        format('woff2');
}

@page {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}
